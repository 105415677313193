<template>
  <div>
    <div class="card">
      <div class="card_head">
        <span v-if="Number(item.question_score)">
          <span class="yuan">
            {{item.paper_question_no}}
          </span>
          <span class="item_title">
            题头编码：</span>{{item.question_code_head}},
        </span>
        <span>
          <span class="item_title">
            题目编号：
          </span>{{item.question_id}},
        </span>
        <span>
          <span class="item_title">
            题目编码：</span>{{item.question_code}},
        </span>
        <!-- 题目来源：{{item.source_name}}, -->
        <span>
          <span class="item_title">
            题目难度：
          </span>{{item.question_difficulty}},
        </span>
        <span class="item_title">题目分数：</span><span style="color:#FE674E">{{item.question_score}}</span>

        <br>
        <div style="margin-top:10px">
          <span>
            <span class="item_title">
              题目类别：
            </span>{{item.question_category_name}},
          </span>
          <span class="typeName"
                v-if="item.question_type_name">
            {{item.question_type_name}}</span>
        </div>
        <div class="point"
             v-if="item.check_point">
          <div class="head">题目知识点：</div>
          <div v-for="itm in item.check_point"
               :key="itm.check_point_id">
            {{itm.check_point_desc}} {{itm.checkpoint_score}};
          </div>
        </div>
        <div class="point"
             v-if="item.knowledge_point">
          <div class="head">知识点：</div>
          <div v-for="itm in item.knowledge_point"
               :key="itm.knowledge_id">
            {{itm.knowledge_desc}} {{itm.knowledge_score}};
          </div>
        </div>
        <div class="point"
             v-if="item.chapter_sections">
          <div class="head">题目章节：</div>
          <div v-for="itm in item.chapter_sections"
               :key="itm.knowledge_id">
            {{itm.chapter_desc}} {{itm.chapter_score}};
          </div>
        </div>
      </div>
      <div class="box-card">
        <div class="clearfix">
          题目内容：
        </div>
        <div class="topic_content"
             v-html="$replaceAll(item.question_content)">
        </div>
        <div class="bottom_border"></div>
      </div>
      <div class="box-card"
           v-if="Number(item.question_score) != 0">
        <div class="clearfix">
          <span>题目答案：</span>
        </div>
        <div class="topic_content"
             style="color:#FF6147;font-weight:bold"
             v-html="$replaceAll(item.question_answer)">
        </div>
        <div class="bottom_border"></div>
      </div>
      <div class="box-card"
           v-if="Number(item.question_score) != 0"
           style="padding-bottom:15px">
        <div class="clearfix">
          <span>题目解释：</span>
        </div>
        <div class="topic_content"
             v-html="$replaceAll(item.question_explain)">
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  data () {
    return {
      topic: [],
    }
  },
  components: {
  },
  mounted () {
    if (this.$route.path == '/examinationEntry/examinationEntry/add' || this.$route.path == '/examinationEntry/proofread') {

    } else {
      document.getElementsByClassName('view_warp')[0].style.background = '#F6F6F6'
    }
  },
  props: ['item', 'index'],
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.box-card {
  padding-top: 10px;
  background: white;
  .clearfix {
    font-size: 18px;
    font-weight: bold;
    height: 18px;
    line-height: 18px;
    border-left: 4px solid #37a0f4;
    text-indent: 1em;
    margin: 20px 0;
  }
  .topic_content {
    margin-left: 20px;
    font-size: 16px;
    padding-right: 20px;
  }
}

.card {
  margin: auto;
  font-size: 14px;
  margin-top: 20px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  .card_head {
    font-size: 16px;
    position: relative;
    line-height: 28px;
    padding: 10px 20px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: #eff8ff;
    .right_btn {
      position: absolute;
      right: 0px;
    }
  }
}

.yuan {
  display: inline-block;
  width: 28px;
  height: 28px;
  background: #68bcff;
  color: white;
  text-align: center;
  line-height: 28px;
  border-radius: 50%;
  margin-right: 10px;
}
.fixed_right {
  position: absolute;
  right: 20px;
  top: 5px;
}
.kaodian {
  display: inline-block;
  color: black;
  font-weight: bold;
  margin-top: 15px;
}
.bottom_border {
  width: 98%;
  height: 10px;
  border-bottom: 1px solid #e8e8e8;
  margin-left: 20px;
}
.typeName {
  display: inline-block;
  height: 28px;
  background: #68bcff;
  padding: 0 15px;
  color: white;
  margin-left: 10px;
  border-radius: 20px;
  line-height: 28px;
}
.item_title {
  color: #393b3d;
  font-weight: bold;
  margin-left: 10px;
}
.point {
  display: flex;
  font-weight: bold;
  .head {
    width: 90px;
    text-align: right;
  }
}
</style>